import "./Cards.css";
interface CardsProps {
  image: string;
  label: string;
  description: string;
}

const Cards = (props: CardsProps) => {
  return (
    <div className="cards-container">
      <div className="cards-image-container">
        <img src={props.image} className="cards-image" />
      </div>
      <h1 className="cards-label">{props.label}</h1>
      <label className="cards-description">{props.description}</label>
    </div>
  );
};

export default Cards;
