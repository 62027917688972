import UnitsTiles from "../../components/UnitsTiles/UnitsTiles";
import "./FleetDetails.css";

interface FleetDetailsProps {}

const FleetDetails = (props: FleetDetailsProps) => {
  return (
    <section className="fleet-details-container" id="fleetDetails">
      <div className="fleet-details-left-content-container">
        <div className="fleet-details-overview-container">
          <h1 className="fleet-details-h1">Fleet Details</h1>
          <h1 className="fleet-details-overview-h1">Overview</h1>

          <h1 className="fleet-details-first-paragraph">
            At JJJM SUN ENTERPRISES, we take pride in maintaining a diverse and
            well-equipped fleet to meet the varied logistics needs of our
            clients. Here's a detailed overview of our fleet:
          </h1>
          <h1 className="fleet-details-second-paragraph">
            All our vehicles are covered by comprehensive and Inland Marine
            Insurance provided by AXA Philippines Life and General Insurance and
            Mercantile Insurance, ensuring protection and peace of mind for our
            operations and clients. We prioritize regular maintenance and timely
            upgrades to keep our fleet in optimal condition, ensuring
            reliability and efficiency in our services.
          </h1>
        </div>
      </div>
      <div className="fleet-details-right-content-container">
        <div className="fleet-details-units-list-container">
          <UnitsTiles
            label="4-Wheeler Trucks"
            numberOfUnits={4}
            description="Four-wheeler trucks are versatile vehicles designed for transporting goods and materials."
          />
          <UnitsTiles
            label="6-Wheeler Trucks"
            numberOfUnits={4}
            description="Six-wheeler trucks are medium to heavy-duty vehicles equipped with three axles—two at the rear and one at the front."
          />
          <UnitsTiles
            label="6-Wheeler Forward Trucks"
            numberOfUnits={4}
            description="Six-wheeler forward trucks are medium to heavy-duty vehicles characterized by their front-engine, rear-wheel drive design."
          />
          <UnitsTiles
            label="10-Wheeler Trucks"
            numberOfUnits={4}
            description="Ten-wheeler trucks are heavy-duty vehicles with five axles—two at the front and three at the rear—providing increased stability and substantial payload capacity."
          />
          <UnitsTiles
            label="Boom Trucks"
            numberOfUnits={4}
            description="Boom trucks are versatile vehicles equipped with a mounted crane or boom arm, allowing them to lift and move heavy loads."
          />
          <UnitsTiles
            label="Mitsubishi L300 FB"
            numberOfUnits={4}
            description="The Mitsubishi L300 FB is a versatile, light commercial vehicle known for its reliability and practicality."
          />
        </div>
      </div>
    </section>
  );
};

export default FleetDetails;
