import "./UnitsTiles.css";

interface UnitsTilesProps {
  label: string;
  numberOfUnits: number;
  description: string;
}

const UnitsTiles = (props: UnitsTilesProps) => {
  return (
    <div className="units-tiles-container">
      <div className="units-tiles-row-container">
        <label className="units-tiles-label">{props.label}</label>
      </div>
      <div className="m8">
        <label className="label-bold">Description:</label>
        <label>{props.description}</label>
      </div>
    </div>
  );
};

export default UnitsTiles;
