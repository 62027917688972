import React from "react";
import "./AboutUs.css";

interface AboutUsProps {}

const AboutUs = (props: AboutUsProps) => {
  return (
    <section className="about-us-container" id="aboutUs">
      <div className="about-us-left-content-container">
        <div className="about-us-content-container">
          <h1 className="about-us-founded-label">
            Founded in 1999{" "}
            <label className="about-us-label">and rooted in a legacy</label>
          </h1>
          <label className="about-us-description">
            We have grown from a small car rental service to a dynamic logistics
            provider. Located in Cabuyao, Laguna, we are known for delivering
            excellence through our comprehensive services and commitment to
            customer satisfaction.
          </label>

          <label className="about-us-description">
            At JJJM Sun Enterprises, we recognize the crucial role of reliable
            logistics and transportation in business success across various
            industries. Our mission is to provide exceptional logistics
            solutions that exceed our clients' expectations. We achieve this by
            leveraging our extensive industry experience, cutting-edge
            technology, and a dedicated team passionate about delivering value
            and efficiency.
          </label>

          <label className="about-us-description">
            Our diverse fleet, including Mitsubishi L300 FBs, 4-wheeler and
            6-wheeler trucks, 6-wheeler forward trucks, 10-wheeler trucks, Boom
            trucks and passenger vehicles, allows us to handle a wide range of
            logistics needs, from small package deliveries to large-scale
            transportation projects. We maintain our vehicles to the highest
            standards and cover them with comprehensive insurance to ensure
            safety and reliability. We pride ourselves on merging traditional
            values with modern logistics solutions, offering clients tailored
            services that drive efficiency and growth.
          </label>
        </div>
      </div>
      <div className="about-us-right-content-container"></div>
    </section>
  );
};

export default AboutUs;
