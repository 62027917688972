import "./FeedbackTile.css";
interface FeedbackTileProps {
  feedback: string;
  companyName: string;
  employeeName: string;
}

const FeedbackTile = (props: FeedbackTileProps) => {
  return (
    <div className="feed-back-tile-container">
      <img src={require("../../assets/images/stars.png")} />
      <p>{props.feedback}</p>

      <p className="feed-back-tile-company-name">{props.companyName}</p>
      <p className="feed-back-tile-employee-name">{props.employeeName}</p>
    </div>
  );
};

export default FeedbackTile;
