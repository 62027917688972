import { useState, useRef } from "react";
import "./LandingPage.css";

import PartnerWithUs from "../PartnerWithUs/PartnerWithUs";
import OurServicesScreen from "../OurServices/OurServicesScreen";
import FleetDetails from "../FleetDetails/FleetDetails";
import AboutUs from "../AboutUs/AboutUs";
import OperationalAreas from "../OperationalAreas/OperationalAreas";
import ContactUs from "../ContactUs/ContactUs";
import HearFromOurClients from "../HearFromOutClients/HearFromOurClients";
import MissionVision from "../MissionVision/MissionVision";
import Hse from "../HSE/Hse";

interface LandingPageProps {}

export enum Sections {
  PARTNER_WITH_US = "Partner with us",
  OUR_SERVICES = "Our Services",
  FLEET_DETAILS = "Fleet Details",
  ABOUT_US = "About Us",
  CONTACT = "Contact",
}

const LandingPage = (props: LandingPageProps) => {
  const partnerWithUsRef = useRef<HTMLDivElement | null>(null);
  const ourServicesRef = useRef<HTMLDivElement | null>(null);
  const fleetDetailsRef = useRef<HTMLDivElement | null>(null);
  const aboutUsRef = useRef<HTMLDivElement | null>(null);
  const operationalAreasRef = useRef<HTMLDivElement | null>(null);
  const contactUsRef = useRef<HTMLDivElement | null>(null);
  const hearFromOurClientsRef = useRef<HTMLDivElement | null>(null);
  const missionVisionRef = useRef<HTMLDivElement | null>(null);
  const hseRef = useRef<HTMLDivElement | null>(null);

  const scrollTo = (section: any) => {
    section.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="container">
      <div ref={partnerWithUsRef}>
        <PartnerWithUs
          scrollTo={scrollTo}
          partnerWithUsRef={partnerWithUsRef}
          ourServicesRef={ourServicesRef}
          fleetDetailsRef={fleetDetailsRef}
          aboutUsRef={aboutUsRef}
          contactUsRef={contactUsRef}
        />
      </div>
      <div ref={ourServicesRef}>
        <OurServicesScreen />
      </div>
      <div ref={fleetDetailsRef}>
        <FleetDetails />
      </div>
      <div ref={aboutUsRef}>
        <AboutUs />
      </div>
      <div ref={hseRef}>
        <Hse />
      </div>
      <div ref={hearFromOurClientsRef}>
        <HearFromOurClients />
      </div>
      <div ref={missionVisionRef}>
        <MissionVision />
      </div>
      <div ref={operationalAreasRef}>
        <OperationalAreas />
      </div>
      <div ref={contactUsRef}>
        <ContactUs
          scrollTo={scrollTo}
          partnerWithUsRef={partnerWithUsRef}
          ourServicesRef={ourServicesRef}
          fleetDetailsRef={fleetDetailsRef}
          aboutUsRef={aboutUsRef}
          contactUsRef={contactUsRef}
          hearFromOurClientsRef={hearFromOurClientsRef}
          missionVisionRef={missionVisionRef}
        />
      </div>
    </div>
  );
};

export default LandingPage;
