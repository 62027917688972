import React, { useEffect } from "react";
import "./PartnerWithUs.css";
import NavBar from "../../components/NavBar/NavBar";
import { Sections } from "../LandingPage/LandingPage";
import PhoneIcon from "../../assets/icons/PhoneIcon.svg";
import MobileIcon from "../../assets/icons/MobileIcon.svg";
import ClockIcon from "../../assets/icons/ClockIcon.svg";
import PinIcon from "../../assets/icons/PinIcon.svg";
import IconWithLabel from "../../components/IconWithLabel/IconWithLabel";
interface PartnerWithUsProps {
  scrollTo: (section: any) => void;
  partnerWithUsRef: React.MutableRefObject<HTMLDivElement | null>;
  ourServicesRef: React.MutableRefObject<HTMLDivElement | null>;
  fleetDetailsRef: React.MutableRefObject<HTMLDivElement | null>;
  aboutUsRef: React.MutableRefObject<HTMLDivElement | null>;
  contactUsRef: React.MutableRefObject<HTMLDivElement | null>;
}

const PartnerWithUs = ({
  scrollTo,
  partnerWithUsRef,
  ourServicesRef,
  fleetDetailsRef,
  aboutUsRef,
  contactUsRef,
}: PartnerWithUsProps) => {
  useEffect(() => {
    console.log("xD");
  }, []);
  return (
    <div className="partner-with-us-container">
      <NavBar
        scrollTo={scrollTo}
        partnerWithUsRef={partnerWithUsRef}
        ourServicesRef={ourServicesRef}
        fleetDetailsRef={fleetDetailsRef}
        aboutUsRef={aboutUsRef}
        contactUsRef={contactUsRef}
      />
      <div className="content">
        <div className="left-container">
          <div className="column">
            <span className="business-label">JJJM SUN ENTERPRISES</span>
            <span className="description">
              Your reliable and trusted partner for trucking and logistics
              services
            </span>
            <IconWithLabel icon={PhoneIcon} label={"+63 49-530-2217"} />
            <IconWithLabel
              icon={MobileIcon}
              label={"+63 966-013-2768, +63 908-753-1551"}
            />
            <IconWithLabel
              icon={ClockIcon}
              label={"Office Hours: Monday to Saturday, 8:00 AM to 5:00 PM"}
            />
            <IconWithLabel
              icon={PinIcon}
              label={
                "Block 8 Lot 3 Hongkong Village Banay-Banay Cabuyao, Laguna"
              }
            />
          </div>
        </div>
        <img
          src={require("../../assets/images/Truck6.png")}
          className="truck"
        />
      </div>
    </div>
  );
};

export default PartnerWithUs;
