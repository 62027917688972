import "./Hse.css";
interface HseProps {}

const Hse = () => {
  return (
    <div className="hse-container">
      <div className="hse-left-container">
        <h1>HEALTH AND SAFETY POLICY</h1>
        <p>
          JJJM Sun Enterprises is dedicated to providing a safe and healthy work
          environment for all employees and contractors, complying with
          applicable health and safety regulations, and preventing workplace
          injuries and illnesses.
        </p>
        <ul>
          <li>
            JJJM Sun Enterprises is dedicated to providing a safe and healthy
            work environment for all employees and contractors. We strive to
            prevent workplace injuries, illnesses, and incidents through
            effective risk management, hazard identification, and control
            measures.
          </li>
          <li>
            We are committed to complying with all applicable health and safety
            regulations, standards, and best practices. We will continuously
            assess and improve our health and safety performance through
            proactive measures, training programs, and employee engagement
            initiatives.
          </li>
          <li>
            Every employee and contractor at JJJM Sun Enterprises has a
            responsibility to prioritize health and safety in their daily
            activities, adhere to established procedures and guidelines, and
            report any unsafe conditions or behaviors to management promptly.
          </li>
        </ul>
      </div>
      <div className="hse-right-container">
        <h1> ENVIRONMENTAL POLICY</h1>

        <p>
          JJJM Sun Enterprises is committed to protecting the environment,
          minimizing pollution, and promoting sustainability in its trucking and
          delivery operations.
        </p>
        <ul>
          <li>
            JJJM Sun Enterprises is dedicated to protecting the environment and
            minimizing our environmental footprint. We recognize the importance
            of conserving natural resources, reducing pollution, and promoting
            environmental sustainability.
          </li>
          <li>
            We are committed to complying with all relevant environmental laws,
            regulations, and permits governing our activities. We will strive to
            prevent pollution, minimize waste generation, and promote resource
            efficiency through responsible management practices and continuous
            improvement initiatives.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Hse;
