import React from "react";
import { Link } from "react-scroll";

import Logo from "../../assets/images/Logo.svg";
import PhoneIcon from "../../assets/images/Call.svg";
import "./NavBar.css";
import { Sections } from "../../screens/LandingPage/LandingPage";

interface NavBarProps {
  scrollTo: (section: any) => void;
  partnerWithUsRef: React.MutableRefObject<HTMLDivElement | null>;
  ourServicesRef: React.MutableRefObject<HTMLDivElement | null>;
  fleetDetailsRef: React.MutableRefObject<HTMLDivElement | null>;
  aboutUsRef: React.MutableRefObject<HTMLDivElement | null>;
  contactUsRef: React.MutableRefObject<HTMLDivElement | null>;
}

const NavBar = ({
  scrollTo,
  partnerWithUsRef,
  ourServicesRef,
  fleetDetailsRef,
  aboutUsRef,
  contactUsRef,
}: NavBarProps) => {
  return (
    <div className="navbar-container">
      <div className="logo-container">
        <img src={Logo} />
      </div>
      <div className="links-container">
        <button
          className="tabs"
          onClick={() => {
            scrollTo(ourServicesRef);
          }}
        >
          <span>Our Services</span>
        </button>
        <button
          className="tabs"
          onClick={() => {
            scrollTo(fleetDetailsRef);
          }}
        >
          <span>Fleet Details</span>
        </button>
        <button
          className="tabs"
          onClick={() => {
            scrollTo(aboutUsRef);
          }}
        >
          <span>About Us</span>
        </button>

        <button
          className="contact-us-btn"
          onClick={() => {
            scrollTo(contactUsRef);
          }}
        >
          <span>Contact</span>
        </button>
      </div>
    </div>
  );
};

export default NavBar;
