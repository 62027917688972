import ClientCards from "../../components/ClientCards/ClientCards";
import "./ContactUs.css";
import PhoneIcon from "../../assets/icons/PhoneIcon.svg";
import EmailIcon from "../../assets/icons/EmailIcon.svg";
import LocationIcon from "../../assets/icons/LocationIcon.svg";
import ClockIcon from "../../assets/icons/ClockIcon.svg";
import ArrowUp from "../../assets/icons/ArrowUp.svg";
import CoreTeamLogo from "../../assets/images/Coreteam.jpeg";
import WartsilaLogo from "../../assets/images/Wartsila.png";
import MarketReachLogo from "../../assets/images/MarketReach.png";
import BarrelmanLogo from "../../assets/images/Barrelman.jpg";
import PlaceholderLogo from "../../assets/images/Placeholder.svg";

interface ContactUsProps {
  scrollTo: (section: any) => void;
  partnerWithUsRef: React.MutableRefObject<HTMLDivElement | null>;
  ourServicesRef: React.MutableRefObject<HTMLDivElement | null>;
  fleetDetailsRef: React.MutableRefObject<HTMLDivElement | null>;
  aboutUsRef: React.MutableRefObject<HTMLDivElement | null>;
  contactUsRef: React.MutableRefObject<HTMLDivElement | null>;
  missionVisionRef: React.MutableRefObject<HTMLDivElement | null>;
  hearFromOurClientsRef: React.MutableRefObject<HTMLDivElement | null>;
}

const ContactUs = (props: ContactUsProps) => {
  return (
    <section className="contact-us-container" id="contactUs">
      <div className="contact-us-top-container">
        <h1>
          JJJM's <label className="contact-us-clients-label">Clients</label>
        </h1>

        <div className="contact-us-client-cards-container">
          <ClientCards
            clientName="Barrelman Corporation"
            clientAddress="Sucat, Parańaque"
            logo={BarrelmanLogo}
          />

          <ClientCards
            clientName="Wartsila Philippines, Inc."
            clientAddress="Cabuyao, Laguna"
            logo={WartsilaLogo}
          />

          <ClientCards
            clientName="Net Center Logistics Corp."
            clientAddress="San Juan, Metro Manila"
            logo={PlaceholderLogo}
          />
        </div>

        <div className="contact-us-client-cards-container">
          <ClientCards
            clientName="Market Reach International Resources Corp."
            clientAddress="Sucat, Parańaque"
            logo={MarketReachLogo}
          />

          <ClientCards
            clientName="Coreteam Philippines"
            clientAddress="Pasig City"
            logo={CoreTeamLogo}
          />

          <ClientCards
            clientName="CSP Brokerage"
            clientAddress="UN Ave, Manila"
            logo={PlaceholderLogo}
          />
        </div>
      </div>
      <div className="contact-us-bottom-container">
        <div className="contact-us-row">
          <div>
            <div className="get-in-touch-container">
              <h1>Get in touch</h1>
            </div>
            <div className="contact-us-icon-with-label-container">
              <img src={PhoneIcon} />
              <label>+63 495 302217</label>
            </div>
            <div className="contact-us-icon-with-label-container">
              <img src={EmailIcon} />
              <label>sales@jjjmsun.com</label>
            </div>
            <div className="contact-us-icon-with-label-container">
              <img src={LocationIcon} />
              <label>Block 8 Lot 3 Hongkong Village, Banay-banay Cabuyao</label>
            </div>
            <div className="contact-us-icon-with-label-container">
              <img src={ClockIcon} />
              <label>
                Office Hours: Monday to Saturday, 8:00 AM to 5:00 PM
              </label>
            </div>
          </div>

          <div className="explore-container">
            <h1>Explore</h1>
            <div className="contact-us-column">
              <a onClick={() => props.scrollTo(props.ourServicesRef)}>
                Trucking Services
              </a>
              <a onClick={() => props.scrollTo(props.fleetDetailsRef)}>
                Fleet Details
              </a>
              <a onClick={() => props.scrollTo(props.hearFromOurClientsRef)}>
                Testimonial and Clients
              </a>
              <a onClick={() => props.scrollTo(props.missionVisionRef)}>
                Mission and Vision
              </a>
            </div>
          </div>
        </div>

        <div className="contact-us-row space-between footer">
          <div className="contact-us-row">
            <label className="company-name">JJJM SUN ENTERPRISES</label>
            <label>2024 ALL RIGHTS RESERVED ©</label>
          </div>
          <div className="contact-us-row">
            <img src={require("../../assets/images/Facebook.png")} />
            <a href="https://www.facebook.com/profile.php?id=61563554617129">
              FOLLOW US ON FACEBOOK!
            </a>
          </div>
        </div>

        <button
          className="arrow-up-btn"
          onClick={() => {
            props.scrollTo(props.partnerWithUsRef);
          }}
        >
          <img src={ArrowUp} />
        </button>
      </div>
      <img
        className="truck-image"
        src={require("../../assets/images/Truck3.png")}
      />
    </section>
  );
};

export default ContactUs;
