import FeedbackTile from "../../components/FeedbackTile/FeedbackTile";
import "./HearFromOurClients.css";

interface HearFromOurClientsProps {}

const HearFromOurClients = (props: HearFromOurClientsProps) => {
  return (
    <section
      className="hear-from-our-clients-container"
      id="hearFromOurClients"
    >
      <div className="hear-from-our-client-content-container">
        <div className="hear-from-our-satisfied-client-container">
          <h1>Hear from our</h1>
          <h1 className="satisfied-clients-label">satisfied clients</h1>
          <p>
            We take pride in delivering exceptional service to our <br />{" "}
            clients. Here’s what they have to say about us:
          </p>

          <img src={require("../../assets/images/Truck2.png")} />
        </div>

        <div className="feedback-list-container">
          <FeedbackTile
            feedback={`"JJJM Sun Enterprises has consistently provided reliable and timely delivery services. Their team is professional and always goes the extra mile."`}
            companyName="Barrelman Corporation"
            employeeName="John Doe"
          />
          <FeedbackTile
            feedback={`"The level of customer service and attention to detail we receive from JJJM Sun Enterprises is unmatched. We highly recommend their services."`}
            companyName="Wartsila Philippines Inc."
            employeeName="John Doe"
          />
          <FeedbackTile
            feedback={`"Our partnership with JJJM Sun Enterprises has been instrumental in streamlining our supply chain. Their commitment to excellence is evident in every interaction."`}
            companyName="Coreteam Philippines"
            employeeName="John Doe"
          />
        </div>
      </div>
    </section>
  );
};

export default HearFromOurClients;
