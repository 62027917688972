import "./MissionVision.css";
import Truck7Logo from "../../assets/images/Truck7.svg";

interface MissionVisionProps {}

const MissionVision = (props: MissionVisionProps) => {
  return (
    <section className="mission-vision-container" id="missionVision">
      <div className="mission-vision-content-container">
        <div className="row">
          <div className="our-mission-container">
            <h1>OUR MISSION</h1>

            <ul>
              <li>Provide a great workplace for our employees</li>
              <li>
                Be the best transportation service provider in the Philippine
              </li>
              <li>
                Exceed our customer's expectations for service, quality and
                value
              </li>
              <li>Serve our communities as a commited corporate citizen</li>
            </ul>
          </div>
          <img src={require("../../assets/images/Employee.png")} />
        </div>

        <div className="row">
          <img src={Truck7Logo} />
          <div className="our-vision-container">
            <h1>OUR VISION</h1>

            <p>
              To be the preferred logistics partner for businesses by
              consistently exceeding our client's expectations and driving value
              through sustainable and innovative logistics solutions.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MissionVision;
