import "./IconWithLabel.css";

interface IconWithLabelProps {
  icon: any;
  label: string;
}

const IconWithLabel = (props: IconWithLabelProps) => {
  return (
    <div className="icon-with-label-container">
      <div className="icon-container">
        <img src={props.icon} />
      </div>
      <span>{props.label}</span>
    </div>
  );
};

export default IconWithLabel;
