import "./ClientCards.css";
interface ClientCardsProps {
  clientName: string;
  clientAddress: string;
  logo: any;
}

const ClientCards = (props: ClientCardsProps) => {
  return (
    <div className="client-cards-container">
      <img src={props.logo} />
      <div className="client-description-container">
        <label>{props.clientName}</label>
        <label className="client-address">{props.clientAddress}</label>
      </div>
    </div>
  );
};

export default ClientCards;
