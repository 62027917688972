import "./OperationalAreas.css";
import Map from "../../assets/images/Map4.svg";

interface OperationalAreasProps {}

const OperationalAreas = (props: OperationalAreasProps) => {
  return (
    <section className="operational-areas-container">
      <div className="operational-areas-right-content-container">
        <div>
          <h1>Operational</h1>
          <h1 className="areas-label">Areas</h1>
        </div>
        <p>
          By effectively managing these operational areas, we can reduce
          operational costs, and maintain a competitive edge in the industry.
        </p>
      </div>
      <div className="operational-areas-left-content-container">
        <img src={Map} />
      </div>
    </section>
  );
};

export default OperationalAreas;
