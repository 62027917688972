import Cards from "../../components/Cards/Cards";
import "./OurServicesScreen.css";

interface OurServicesScreenProps {}

const OurServicesScreen = (props: OurServicesScreenProps) => {
  return (
    <section className="our-services-container" id="ourServices">
      <div className="our-services-content-container">
        <div className="trucking-services-label-container">
          <label className="trucking-services-label">Trucking Services</label>
        </div>
        <div className="our-services-card-container">
          <Cards
            image={require("../../assets/images/FreightTransportation.png")}
            label="Freight Transportation"
            description="Reliable delivery of goods across Luzon."
          />
          <Cards
            image={require("../../assets/images/SpecializedTransportation.png")}
            label="Specialized Transportation"
            description="Handling of oversized or sensitive cargo."
          />
          <Cards
            image={require("../../assets/images/IntermodalTransportation.png")}
            label="Combined Transportation"
            description="Combining various transport modes for efficiency."
          />
        </div>
      </div>
    </section>
  );
};

export default OurServicesScreen;
